.btn-login-wrap {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.btn-login {
  background-color: transparent;
  display: block;
  width: 100%;
  cursor: pointer;
  background-image: none;
  padding: 8px 50px;
  margin-bottom: 20px;
  border-radius: 0;
  border: 2px solid #60bdc7;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
}

.btn-login {
  box-shadow: inset 0 0 0 0 #60bdc7;
}

.btn-login:focus,
.btn-login:hover,
.btn-login:active {
  background-color: transparent;
  color: #fff;
  box-shadow: inset 14rem 0 0 0 #60bdc7;
}

.login-btn1:focus,
.login-btn1:hover,
.login-btn1:active {
  background-color: transparent;
  color: #fff;
}

.btn-login:after {
  content: "\0279E";
  font-family: "Material Icons";
  color: white;
  vertical-align: middle;
  margin-left: 20px;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-login:hover:after,
.btn-login:active:after,
.btn-login:focus:after {
  content: "\0279E";
  font-family: "Material Icons";
  color: #fff;
}
