.container {
  /* border: 2px solid green; */
  position: absolute;
  width: 960px;
}

.cannon {
  position: relative;
  left: 40%;
  width: 465px;
  height: 359px;
}

.rotate {
  -webkit-animation:spin 3s linear 3s;
  -moz-animation:spin 3s linear 3s;
  animation:spin 3s linear 3s;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

/* .shake {
  -webkit-animation:shake 4s infinite;
  -moz-animation:shake 4s infinite;
  animation:shake 4s infinite;
  transform: translate3d(3px, 0, 0);
} */

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  10%,
  70% {
    transform: translate3d(5px, 0, 0);
  }
  20%,
  40%,
  60% {
    transform: translate3d(-6px, 0, 0);
  }
  30%,
  50% {
    transform: translate3d(6px, 0, 0);
  }
}

.hide {
  display: none;
}

.smoke {
  position: relative;
  left: 20%;
  top: -480px;
  width: 160px;
  transform: rotate(-30deg);
}

/* .feliz {
  position: relative;
  left: 19%;
  top: -460px;
  width: 220px;
  transform: rotate(20deg);
  -webkit-animation:slide 9s infinite;
  -moz-animation:slide 9s infinite;
  animation:slide 9s infinite;
} */

@keyframes slide {
  90% {
    transform: translate3d(-8px, -50px, 0);
  }
}

/* .btn {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  top: 450px;
  left: 50%;
  background-color: white;
  color: #cc0005;
  border-radius: 4px;
  border: 3px solid #cc0005;
  width: 100px;
  height: 40px;
}

.btn:hover {
    background-color: #cc0005;
    color: white;
    cursor: pointer;
} */

/* FIRE is below */
.fire{
  margin:-35% 80%;
  width: 50px;
  height: 50px;
  position: relative;
  transform-origin:center bottom;
  animation-name: flicker;
  animation-duration:3ms;
  animation-delay:200ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.flame{
  bottom:0;
  position:absolute;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  transform:rotate(-45deg) scale(1.3,1.3);
}

.yellow{
  left:15px; 
  width: 30px;
  height: 30px;
  background:gold;
  box-shadow: 0px 0px 9px 4px gold;
}

.orange{
  left:10px; 
  width: 40px;
  height: 40px;
  background:orange;
  box-shadow: 0px 0px 9px 4px orange;
}

.red{
  left:5px;
  width: 50px;
  height: 50px;
  background:OrangeRed;
  box-shadow: 0px 0px 5px 4px OrangeRed;
}

.white{
  left:15px; 
  bottom:-4px;
  width: 30px;
  height: 30px;
  background:white;
  box-shadow: 0px 0px 9px 4px white;
}

@keyframes flicker{
  0%   {transform: rotate(-1deg);}
  20%  {transform: rotate(1deg);}
  40%  {transform: rotate(-1deg);}
  60%  {transform: rotate(1deg) scaleY(1.04);}
  80%  {transform: rotate(-2deg) scaleY(0.92);}
  100% {transform: rotate(1deg);}
}
