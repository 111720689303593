@keyframes pulse {
  0%, 50%  {
    animation-timing-function: ease-out;
    transform: scale(1);
    transform-origin: center center;
  }

  5% {
    animation-timing-function: ease-in;
    transform: scale(0.91);
  }

  7.5% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  16.5% {
    animation-timing-function: ease-in;
    transform: scale(0.87);
  }

  22.5% {
    animation-timing-function: ease-out;
    transform: scale(1.1);
  }
}

.winnerAnimation {
  animation: pulse 8s cubic-bezier(0.32, 0, 0.67, 0) 0ms infinite normal none;
}